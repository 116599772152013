import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import { useMedia } from "use-media";

import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const VidImg = require("../../../assets/img/integration/bamboohr/video_img_servicenow.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/servicenow_blog/1.png");
const image3 = require("../../../assets/images/servicenow_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/servicenow_blog/3.png");
const image6 = require("../../../assets/images/servicenow_blog/4.png");
const image7 = require("../../../assets/images/servicenow_blog/5.png");
const image8 = require("../../../assets/images/servicenow_blog/6.png");
const image9 = require("../../../assets/images/servicenow_blog/7.png");
const image10 = require("../../../assets/images/servicenow_blog/8.png");
const image11 = require("../../../assets/images/servicenow_blog/9.png");
const image12 = require("../../../assets/images/servicenow_blog/10.png");
const image13 = require("../../../assets/images/servicenow_blog/11.png");
const image14 = require("../../../assets/images/servicenow_blog/12.png");
const image15 = require("../../../assets/images/servicenow_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free ServiceNow Chatbot Guide: Build Your ServiceNow Support Chatbot"
        description="Build a ServiceNow chatbot with no coding required. Workativ offers quick and easy tools to automate workplace support in minutes. Start now!"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to ServiceNow Chatbot   
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is ServiceNow Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does ServiceNow Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is ServiceNow Chatbot used for?
                          </AnchorLink>

                          <AnchorLink offset={180} href="#section4">
                            4. Best ServiceNow Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Business benefits & use cases of ServiceNow
                            chatbot with Workativ Assistant
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. How to Build ServiceNow Chatbot Without coding
                          </AnchorLink>

                          <AnchorLink offset={180} href="#section7">
                            7. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          Using ServiceNow for IT Service Management (ITSM) is
                          great and all, sure&nbsp;
                          <a href="https://www.acorio.com/100-servicenow-statistics-next-powerpoint-presentation/">
                            employee satisfaction grows from 1.5/5 to 3.5/5
                          </a>{" "}
                          or companies using ServiceNow but did you know you can
                          make internal IT support experience even more engaging
                          for your employees using  
                          <a href="https://workativ.com/solutions/conversational-AI">
                            Conversational AI?
                          </a>{" "}
                          Hearing about this now only? Don’t worry, we got you
                          covered. By automating ServiceNow tasks with a
                          Conversational AI/Chatbot, you are bound to provide
                          self-service that employees love.
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is ServiceNow Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            ServiceNow Chatbot is an{" "}
                            <a href="https://workativ.com/conversational-ai-platform/blog/service-now-virtual-agent-pros-cons">
                              AI powered ServiceNow
                            </a>{" "}
                            which helps companies auto-resolve 80% of
                            customer/employee issues within seconds. Using the
                            ServiceNow chatbot, companies benefit by automating
                            repetitive issues, saving costs, reducing time to
                            resolution, delivering faster support and more. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            {" "}
                            ServiceNow enhances chatbot capabilities with an
                            AI-driven, intelligent conversational experience
                            from beginning to finish.{" "}
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Virtual Agent employs natural language understanding
                            (NLU) that has been customised to ServiceNow
                            processes and is supplemented by conversational
                            templates for typical HR, IT, and customer service
                            situations. In addition, ServiceNow technologies
                            enable users to construct declarative chatbots
                            without natural language understanding, answering
                            the demands of enterprises whose clients need a more
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              straightforward chatbot experience.
                            </a>{" "}
                            Using a sophisticated graphical interface and
                            drag-and-drop capabilities, and intended for smooth
                            connection with a variety of channels and
                            technologies, Virtual Agent facilitates the creation
                            of successful chatbots throughout your enterprise.{" "}
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does ServiceNow Chatbot works? 
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                              ServiceNow chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with them as well as to extract
                            required data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with a ServiceNow chatbot, it can not only
                            have those benefits, but also extend the
                            functionalities of ServiceNow tool using advanced
                            integration and workflows. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Daily, HR and IT departments are overwhelmed with
                            hundreds of service requests. These are often
                            elementary or ordinary inquiries that may be
                            unanswered owing to a high volume of incoming calls
                            or service tickets.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            These regular inquiries are handled by chatbots,
                            enabling HR and IT experts to concentrate on their
                            primary responsibilities and be more productive.
                            Chatbots use NLP technology to comprehend and reply
                            to service requests using natural conversational
                            language.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            With ServiceNow, you can simply create and{" "}
                            <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-chatbot-for-employee-service">
                              deploy chatbots to answer employee inquiries
                            </a>{" "}
                            and enhance the effectiveness of your HR and IT
                            helpdesks over time. Using BotCore, you can also
                            connect them to your ServiceNow Virtual Assistant
                            and utilise them as child bots inside a bigger
                            business bot. This enhanced efficiency and output
                            substantially reduces your service management
                            expenses.
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is ServiceNow chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            A ServiceNow chatbot can be quite helpful not just
                            for your users but also for the agents as it helps
                            automate user interactions on repetitive tasks and
                            helps your team focus on more important and complex
                            tasks that require{" "}
                            <a href="https://workativ.com/conversational-ai-platform/blog/itsm-eco-system-ms-teams-gen-ai">
                              human touch.
                            </a>
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            We have compiled a list of most repetitive tasks
                            that you can automate using ServiceNow chatbot:{" "}
                          </p>

                          <ul
                            class="font-section-normal-text"
                            style="float: left; width: 100%;"
                          >
                            <li>Creating a ServiceNow Incident </li>
                            <li>Updating a ServiceNow Incident </li>
                            <li>
                              Retrieving details about a ServiceNow Incident{" "}
                            </li>
                            <li>Creating a User </li>
                            <li>Removing a User </li>
                            <li>Updating User Details </li>
                            <li>Retrieving details about a User </li>
                          </ul>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best ServiceNow Chatbot platform
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant is an{" "}
                            <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                              AI-powered no-code platform for building
                              contextual chatbots
                            </a>{" "}
                            with automated workflows for business apps. Workativ
                            Assistant’s chatbots can be easily added to you
                            Slack/Microsoft Teams workspace so that your
                            employees can self-serve their IT issues on-the-go,
                            anytime. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            By integrating ServiceNow with Workativ Assistant,
                            you ensure your employees get the{" "}
                            <a href="https://workativ.com/features.html">
                              IT/HR support
                            </a>
                            they’d love. 
                          </p>
                        </div>
                        <NocodeWrapper />
                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits & use cases of&nbsp; ServiceNow
                            chatbot &nbsp;with Workativ Assistant <br />
                            <span className="font-section-normal-text-medium">
                              Make your IT Service Management (ITSM) processes
                              faster
                            </span>
                          </h2>
                          <p className="font-section-normal-text line-height-2">
                            Workativ Assistant is an AI-powered no-code platform
                            for building contextual chatbots with{" "}
                            <a href="https://workativ.com/assistant/workflow-automation">
                              automated workflows that resolve repetitive IT
                              issues
                            </a>{" "}
                            for your employees in real-time just by conversing
                            with them on your business’ Slack or Microsoft Teams
                            workspace.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            With Workativ Assistant and ServiceNow in your
                            arsenal, you can automate the following ServiceNow
                            tasks like a breeze by creating a ServiceNow chatbot
                            in just minutes.
                          </p>
                          <ExistingBlogCta
                            ContentCta="Auto-resolve 60% of ServiceNow Employee Queries."
                            ButtonText="Book a Demo"
                            isColor="white"
                            backgroundImage={cta_2}
                            mobileBackgroundImage={cta_2_mob}
                          />
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Creating a ServiceNow Incident
                            </span>
                            — Be it a laptop display or a network issue, your
                            employees can raise a ServiceNow incident from the
                            comfort of their Slack or Microsoft Teams mobile or
                            web app using the ServiceNow virtual agent there
                            powered by Workativ Assistant.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Updating a ServiceNow Incident
                            </span>
                            — After your help desk agent resolves a ServiceNow
                            Incident, instead of logging in to your business’
                            ServiceNow portal, they can tell the ServiceNow
                            chatbot created with Workativ Assistant to do it for
                            them.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving details about a ServiceNow Incident
                            </span>
                            — In case an employee wants to know the details
                            about their ServiceNow incident like the incident
                            status, they can do so by asking the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/it-support-chatbot">
                              Workativ Assistant chatbot
                            </a>{" "}
                            about it.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Creating a User
                            </span>
                            — If there’s a new joiner, help desk agents can add
                            the new employee to your business’ ServiceNow
                            workspace with Workativ Assistant’s chatbot.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Removing a User
                            </span>
                            — If there’s an outgoing employee, your help desk
                            agents can remove her/him from your business’
                            ServiceNow workspace just by having a conversation
                            with Workativ Assistant’s chatbot.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Updating User Details
                            </span>
                            — In case an employee changes his/her details like
                            permanent address, your help desk agent can update
                            the same using Workativ Assistant’s chatbot.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving details about a User
                            </span>
                            — If a help desk agent wants to know details about
                            an employee in order to close a ServiceNow incident,
                            he or she can talk to Workativ Assistant’s chatbot
                            and get the necessary details in an instant.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Now that you know what all you can do with Workativ
                            Assistant and ServiceNow combined together, let’s
                            take a look at how you can set up the automation and
                            conversation/dialog flow for the same.  
                          </p>
                          <h2 className="font-section-sub-header-small-bold">
                            The Key Benefits that you’d get
                            with Workativ Assistant
                          </h2>

                          <h3 className="font-section-normal-text-medium">
                            1. Faster business processes
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            No more logging in and out of different applications
                            and switching between them endlessly. With Workativ
                            Assistant, you make it easier on your employees by
                            clubbing existing business apps together and
                            providing their services through your business’
                            <a
                              href="https://workativ.com/conversational-ai-platform/chatbot-channels"
                              style={"color: #007bff;"}
                            >
                              &nbsp;Slack or Microsoft Teams workspace. &nbsp;
                            </a>{" "}
                            Employees will then just have to open their Slack or
                            Microsoft Teams mobile/web app to do what they want
                            by having a chat with Workativ Assistant’s chatbot
                            there, on-the-go, 24×7.
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. Improved IT/HR support experience for employees
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            No more repetitive calls/tickets to help desk or HR.
                            With{" "}
                            <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                              Workativ assistant’s chatbot, &nbsp;
                            </a>{" "}
                            you make sure your employees don’t have to wait on
                            endless calls to get what they’re looking for which
                            in turn leads to your help desk agents’/ HR
                            personnel’s productivity increase, letting them
                            concentrate on the complex tasks at hand that
                            requires a human touch.   
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. Greater ROI    
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            By making Workativ Assistant’s chatbot a part of
                            your business’ internal IT support, you ensure that
                            you get the best ROI on your business’ internal
                            workplace support by{" "}
                            <a href="https://workativ.com/conversational-ai-platform/call-deflection">
                              deflecting unnecessary calls/tickets to help
                              desk/HR &nbsp;
                            </a>{" "}
                            thereby improving overall employee morale. And
                            that’s how Workativ Assistant can help you if you’re
                            using ServiceNow or any business app for the matter.
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to{" "}
                            <a href="https://workativ.com/conversational-ai-platform/how-to-build-servicenow-chatbot">
                              Build a ServiceNow chatbot
                            </a>{" "}
                            for IT/HR process automation
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name.    
                             
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot  
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt ServiceNow bot from the bot
                            Marketplace    
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download ServiceNow
                            workflows. 
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a ServiceNow app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download ServiceNow app workflow from
                            marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the ServiceNow application    
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your ServiceNow account    
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the ServiceNow chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your ServiceNow chatbot   
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy ServiceNow chatbot  in your favourite
                            chat channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-0"
                          ></img>
                          <img loading="lazy"></img>
                        </div>

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build ServiceNow Chatbot with workflow
                              automation
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/4Xd4Jto-EqU"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section7"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            In conclusion, ServiceNow Chatbot is an excellent
                            way to improve communication and collaboration
                            within your organization. The chatbot can be easily
                            configured to meet the specific needs of your team,
                            and it provides a quick and easy way to get answers
                            to common questions. If you're looking to increase
                            communication and collaboration within your
                            organization, consider using a ServiceNow Chatbot.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Workativ enables you to build an AI bot for your
                            workplace in minutes, with no coding required.
                            Workativ is a free, no-code SaaS platform that
                            enables businesses to create, automate, and deploy
                            conversational AI chatbots in under an hour.
                            <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                              &nbsp;Try our ServiceNow chatbot for FREE.{" "}
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                              >
                                A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/free-conversational-ai-platform"
                              >
                                Best Free Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service"
                              >
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup /> */}

            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free ServiceNow Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="servicenow chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
